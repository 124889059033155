import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
      <div id="copyright">
          © David Mejia
    </div>
  );
};

export default Footer
